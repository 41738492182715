* {
    box-sizing: border-box;
}

.react-geocoder {
    position: relative;
    font-size: 14px;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 2em;
    letter-spacing: -0.05px;
    background-color: #fff;
    width: 100%;
    min-width: 240px;
    z-index: 1;
    border-radius: 0px;
    transition: width .25s, min-width .25s;    
    box-shadow: none;  
}
.react-geocoder:focus, .react-geocoder input:focus {
    border: none;
    outline: none;
}

.react-geocoder-results {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    /* border: 1px solid black; */
    width: 100%;
}

.react-geocoder-item {
    padding: 4px;
    /* border-bottom: 1px solid black; */
    cursor: pointer
}